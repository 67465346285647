<div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0 text-dark">Manage Customers</h1>
        </div><!-- /.col -->
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active">Centers</li>
           
          </ol>
        </div><!-- /.col -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <!-- Main content -->
  <section class="content">
      <div class="container-fluid card">
       
            <div class="form-group">
                <label>Enter Customer Saving Account No / Loan Account Number</label>
                <div class="d-flex flex-row">   <input class="form-control w-50 mt-2" type="text" [(ngModel)]="_customer_saving_no" placeholder="Saving account number"/>
                    <button class="btn btn-primary ml-3" (click)="fnSearch()">Search</button></div>
             
            </div>
   
       
      </div>
  </section>
  
  <!-- /.content -->


