<aside class="main-sidebar sidebar-dark-primary elevation-4">


    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img [src]="SessionData.profile ? SessionData.profile : 'https://www.w3schools.com/howto/img_avatar.png'" class="img-circle elevation-2" alt="User Image">
        </div>
        <div class="info">
          <a [routerLink]="['/dashboard']"  class="d-block">{{SessionData.employee_first_name}}  {{SessionData.employee_last_name}}</a>
        </div>
      </div>
  
      <!-- Sidebar Menu -->
  
      
      <nav class="mt-2">    
      <ul class="nav nav-pills nav-sidebar flex-column"  data-widget="treeview" role="menu" data-accordion="false">
        <!-- Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library -->
        <li class="nav-item has-treeview menu-open">
          <a href="#" [routerLink]="['/superdashboard']"  routerLinkActive="active" class="nav-link">
            <i class="nav-icon fas fa-tachometer-alt"></i>
            <p>
              Superadmin Dashboard
            </p>
          </a>
        
        </li>
      
        <li class="nav-item has-treeview"  >
          <a href="#"  class="nav-link">
            <i class="nav-icon fas fa-copy"></i>
            <p>
              Bank Management
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">



  

            <li class="nav-item" >
              <a [routerLink]="['/Activebanks']" routerLinkActive="active" class="nav-link">
                <i class="far fa-circle nav-icon"></i>
                <p>Active Banks List</p>
              </a>
            </li>

            <!-- <li class="nav-item" >
              <a [routerLink]="['/role']" routerLinkActive="active" class="nav-link">
                <i class="far fa-circle nav-icon"></i>
                <p>Inactive Banks List</p>
              </a>
            </li> -->
            
        
       
          </ul>
        </li>
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
  